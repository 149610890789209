import styled from 'styled-components';
import device from 'device';
import { ReactComponent as PDF } from 'assets/images/icons/pdf.svg';

export const DocumentsWrapper = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 24px;
  padding: 50px;

  @media ${device.laptopXL} {
    grid-template-columns: repeat(4, minmax(250px, 1fr));
  }
  @media ${device.laptopL} {
    grid-template-columns: repeat(3, minmax(250px, 1fr));
  }
  @media ${device.laptop} {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
  @media ${device.tablet} {
    grid-template-columns: repeat(1, minmax(250px, 1fr));
  }
`;

export const DocumentItem = styled.div`
  background-color: ${props => props.theme.fullWhite};
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  min-height: 156px;
  transition: all 0.3s;

  @media ${device.tablet} {
    min-height: 90px;
  }
`;

export const DocumentItemTitle = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: row;
  line-height: 30px;
  div {
    font-weight: 400;
    font-size: 18px;
    color: ${props => props.theme.blackText};
  }
`;

export const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ButtonDocument = styled.div`
  border-radius: 6px;
  width: 90px;
  height: 40px;
  background-color: ${({ theme, blue }) =>
    blue ? theme.btnLightColor : 'unset'};
  position: relative;

  a {
    text-decoration: none;
    color: ${({ theme, blue }) =>
      blue ? theme.fullWhite : theme.btnLightColor};
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    line-height: 40px;
    text-align: center;
  }
`;

export const PDFIcon = styled(PDF)`
  min-width: 24px;
  height: 24px;
  margin-top: 5px;
`;
