import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet';
import { Field, Formik } from 'formik';
import { parse } from 'query-string';
import * as Yup from 'yup';
import UserContext from 'utils/contexts/User';
import ModalContext from 'utils/contexts/Modal';
import { ERROR_STATUSES } from 'utils/constants/errors';
import { useAxiosSubmitingEffectWithHeaders } from 'utils/hooks/axiosHook';
import { supportFormOptions } from 'utils/services/request/auth';
import { useQuery } from 'utils/location';
import { keyValuesMapper } from 'utils/constants/supportMessageTopics';
import CustomFileInput from 'ui-kit/CustomField/FileInput';
import CustomTextarea from 'ui-kit/CustomField/Textarea';
import { Dialog, DialogModal } from 'ui-kit/Modal/Modal';
import Dot from 'ui-kit/DotPreloader/styled-ui';
import { ButtonText, SupportSubmitButton, Agreement } from 'ui-kit/Button';
import CustomInput from './Input';
import {
  Error,
  FormWrapper,
  Wrapper,
  SupportWrapper,
  Row,
  FormContentSection,
  FormUploadDocumentHelpText,
  WrapInfo,
  LabelCheckbox,
  HiddenInput,
  Checkbox,
  LabelText,
} from './styled-ui';
import SelectText from './selectText';

const requestSupportStatus = 'OK';

const Support = () => {
  const query = useQuery();
  const params = parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const user = useContext(UserContext);
  const { openModal } = useContext(ModalContext);
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [showModal, setShowModal] = useState(false);
  const [showPreloader, setShowPreloader] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [token, setToken] = useState('');
  const [, setText] = useState('');
  const userEmail = user.data && user.data.email ? user.data.email : '';

  const handleReCaptchaVerify = useCallback(
    tokenS => {
      if (token) return;
      setToken(tokenS);
    },
    [setToken, token]
  );
  const supportSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('support.errors.requiredField'))
      .max(30, t('support.errors.nameMax', { count: 30 })),

    email: Yup.string()
      .trim(t('support.errors.emailTrim'))
      .email(t('support.errors.incorrectEmailFormat'))
      .required(t('support.errors.requiredField')),

    topic: Yup.string()
      .required(t('support.errors.requiredField'))
      .max(30, t('support.errors.topicMax', { count: 30 })),

    message: Yup.string()
      .required(t('support.errors.requiredField'))
      .max(1000, t('support.errors.messageMax', { count: 1000 })),

    files: Yup.array().max(5, t('support.errors.filesMax', { count: 5 })),
  });

  const stateSubmittingSupportForm = useAxiosSubmitingEffectWithHeaders(
    supportFormOptions
  );

  useEffect(() => {
    if (
      !stateSubmittingSupportForm.fetching &&
      stateSubmittingSupportForm.loaded
    ) {
      if (stateSubmittingSupportForm.data) {
        if (stateSubmittingSupportForm.data.status === requestSupportStatus) {
          setShowModal(true);
          setShowPreloader(false);
          stateSubmittingSupportForm.form.actions.resetForm();
        }
      } else if (stateSubmittingSupportForm.error) {
        setShowPreloader(false);
        const { actions } = stateSubmittingSupportForm.form;
        if (
          stateSubmittingSupportForm.error.status ===
          ERROR_STATUSES.INVALID_EMAIL
        ) {
          actions.setErrors({
            email: t('support.errors.incorrectEmailFormat'),
          });
        }
        if (
          stateSubmittingSupportForm.error.status ===
          ERROR_STATUSES.INVALID_RECAPTCHA
        ) {
          openModal({
            isError: true,
            message: t('errorModal.title'),
          });
        }
      }
    }
    if (
      stateSubmittingSupportForm.fetching &&
      !stateSubmittingSupportForm.loaded
    ) {
      setShowPreloader(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stateSubmittingSupportForm.fetching,
    stateSubmittingSupportForm.loaded,
    stateSubmittingSupportForm.data,
    stateSubmittingSupportForm.error,
  ]);

  const onSubmitHandler = useCallback(
    async (values, actions) => {
      const formData = new FormData();
      if (values.files.length > 0) {
        values.files.map(file => {
          formData.append('files', file);
          return formData;
        });
      }
      formData.append(
        'request',
        JSON.stringify({
          name: values.name ? values.name.trim() : '',
          email: values.email ? values.email.trim() : '',
          topic: values.topic ? values.topic.trim() : '',
          message: values.message ? values.message.trim() : '',
        })
      );

      if (!process.env?.REACT_APP_RECAPTCHA_KEY || !executeRecaptcha) {
        stateSubmittingSupportForm.setFormAndSubmit({
          values: formData,
          actions,
        });
        return;
      }
      const result = await executeRecaptcha('support');
      stateSubmittingSupportForm.setFormAndSubmit({
        headers: {
          recaptcha: result,
        },
        values: formData,
        actions,
      });
    },
    [executeRecaptcha, stateSubmittingSupportForm]
  );

  const urlTopic = query.get('topic');
  const message = query.get('message');

  const initialValues = {
    name: '',
    email: userEmail || '',
    topic: (urlTopic && keyValuesMapper[urlTopic]) || 'Ошибка работы сервиса',
    message: message ? t(`support.initialMessages.${message}`, params) : '',
    files: [],
  };

  const options = Object.values(keyValuesMapper).map(item => ({
    label: item,
    value: item,
  }));

  return (
    <>
      <Helmet>
        <title>Online поддержка | Whitebird</title>
        <meta
          name="description"
          content="Поддержка компании Whitebird. Возникли вопросы при покупке или продаже Биткоина? Обращайтесь!"
        />
        <meta property="og:title" content="Online поддержка | Whitebird" />
        <meta
          property="og:description"
          content="Поддержка компании Whitebird. Возникли вопросы при покупке или продаже Биткоина? Обращайтесь!"
        />
      </Helmet>
      <SupportWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmitHandler}
          validateOnChange={false}
          validationSchema={supportSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <FormWrapper>
              <Wrapper onSubmit={handleSubmit}>
                <Row>
                  <Field
                    id="name"
                    type="text"
                    name="name"
                    onChange={handleChange}
                    component={CustomInput}
                    placeholder={t('support.nameSurname')}
                    label={t('support.introduce')}
                    autoComplete="off"
                    onBlur={handleBlur}
                  />

                  {errors.name && touched.name && (
                    <Error isSupportPage>{errors.name}</Error>
                  )}
                </Row>
                <Row>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    onChange={handleChange}
                    component={CustomInput}
                    placeholder="yourmail@mail.com"
                    label="Email"
                    autoComplete="email"
                    disabled={userEmail}
                  />

                  {errors.email && touched.email && (
                    <Error>{errors.email}</Error>
                  )}
                </Row>
                <Row>
                  <Field
                    id="topic"
                    name="topic"
                    onChange={handleChange}
                    component={SelectText}
                    label={t('support.topic')}
                    options={options}
                    setText={setText}
                    isSearchable={false}
                  />
                  {errors.topic && touched.topic && (
                    <Error>{errors.topic}</Error>
                  )}
                </Row>
                <Row>
                  <div style={{ position: 'relative' }}>
                    <Field
                      id="message"
                      type="text"
                      name="message"
                      onChange={handleChange}
                      component={CustomTextarea}
                      placeholder={t('support.messageProblem')}
                      label={t('support.youMessage')}
                      autoComplete="off"
                      rows={5}
                      onBlur={handleBlur}
                    />
                    {errors.message && touched.message && (
                      <Error>{errors.message}</Error>
                    )}
                  </div>
                </Row>
                <Row clear>
                  <FormUploadDocumentHelpText>
                    <FormContentSection>
                      <Field
                        id="files"
                        name="files"
                        component={CustomFileInput}
                        type="text"
                        accept=".png, .jpg, .jpeg, .pdf"
                        propFiles={values.files}
                      />
                    </FormContentSection>

                    <WrapInfo>
                      <FormUploadDocumentHelpText
                        error={errors.files && touched.files}
                      >
                        {t('support.validFormats')}
                      </FormUploadDocumentHelpText>
                      <FormUploadDocumentHelpText
                        error={errors.files && touched.files}
                      >
                        {' '}
                        {t('support.maxFiles')}
                      </FormUploadDocumentHelpText>
                      <FormUploadDocumentHelpText
                        error={errors.files && touched.files}
                      >
                        {' '}
                        {t('support.maxSizes')}
                      </FormUploadDocumentHelpText>
                    </WrapInfo>
                  </FormUploadDocumentHelpText>
                </Row>
                <Agreement>
                  <LabelCheckbox>
                    <HiddenInput
                      type="checkbox"
                      checked={agreement}
                      onChange={() => setAgreement(!agreement)}
                    />
                    <Checkbox />
                    <LabelText>
                      {t('support.checkboxText')}{' '}
                      <Link
                        target="_blank"
                        to="/assets/files/personal-data-processing-policy_03_2024.pdf"
                      >
                        {t('support.personalDataProcessingPolicy')}
                      </Link>
                    </LabelText>
                  </LabelCheckbox>
                </Agreement>
                {process.env?.REACT_APP_RECAPTCHA_KEY && (
                  <GoogleReCaptcha
                    action="support"
                    onVerify={handleReCaptchaVerify}
                  />
                )}
                <SupportSubmitButton
                  type="submit"
                  disabled={showPreloader || !agreement}
                >
                  {showPreloader ? (
                    <div>
                      {' '}
                      <Dot />
                      <Dot />
                      <Dot />
                    </div>
                  ) : (
                    <ButtonText>{t('support.send')}</ButtonText>
                  )}
                </SupportSubmitButton>
              </Wrapper>
            </FormWrapper>
          )}
        </Formik>

        {showModal && (
          <DialogModal
            Component={Dialog}
            title={t('support.titleSupport')}
            message={t('support.requestSupport')}
            cancelButtonName={requestSupportStatus}
            notification
            acceptFunc={() => {
              setShowModal(false);
            }}
            onClose={() => {
              setShowModal(false);
            }}
            t={t}
          />
        )}
      </SupportWrapper>
    </>
  );
};

export default Support;
