import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import Checkbox from 'ui-kit/Checkbox';
import Spinner from 'ui-kit/Spinner';
import { Button } from 'ui-kit/Button';
import {
  ButtonWrapper,
  CheckboxText,
  ModalTitle,
  ModalWrapper,
} from './styled-ui';

const PublicOfferModal = ({ t, handleGetReferralLink, isLoading }) => {
  const [checked, setChecked] = useState(false);
  return (
    <ModalWrapper>
      <ModalTitle>{t('profile.referralProgram.publicOfferTitle')}</ModalTitle>
      <Checkbox
        checked={checked}
        onChange={() => setChecked(!checked)}
        disabled={isLoading}
      >
        <CheckboxText>
          <Trans
            i18nKey="profile.referralProgram.termsPublicOffer"
            t={t}
            components={{
              linkTag: (
                <a
                  href="/assets/files/offer-partner-agreement_03_09_2024.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('profile.referralProgram.publicOffer')}
                </a>
              ),
            }}
          />
        </CheckboxText>
      </Checkbox>
      <ButtonWrapper>
        <Button disabled={!checked} onClick={handleGetReferralLink}>
          {isLoading ? <Spinner /> : t('profile.referralProgram.accept')}
        </Button>
      </ButtonWrapper>
    </ModalWrapper>
  );
};

export default PublicOfferModal;
