import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNil, pathOr, propOr } from 'ramda';
import moment from 'moment/moment';
import { parse } from 'query-string';
import { Form } from 'formik';
import { SUPPORT } from 'constants/routings';
import UserContext from 'utils/contexts/User';
import ClientContext from 'utils/contexts/Client';
import CurrenciesContext from 'utils/contexts/Currencies';
import InformationContext from 'utils/contexts/Information';
import { cryptoCardCheckLimit } from 'utils/services/request/alfa';
import { fromLocaleNumber, localeNumberFromOutputNumber } from 'utils/number';
import requestErrors from 'utils/constants/requestErrors';
import { getClientTestingNeeded } from 'utils/user';
import {
  getCurrencyValue,
  getFiatCurrencyFromPair,
  isCrypto,
} from 'utils/crypto';
import {
  getBlockchainFeeValueCalculation,
  getServiceFeeValueCalculation,
} from 'utils/fees';
import { PROMOCODE_REG_EXP } from 'utils/regexp';
import {
  CRYPTO_CURRENCIES,
  currenciesLogo,
  FIAT_CURRENCIES,
  NETWORK_TYPES,
} from 'utils/constant';
import {
  PAYMENT_STATUS,
  SETTLEMENT,
  ORDER_TYPES,
  ALFA,
} from 'utils/constants/paymentMethods';
import { walletTypes } from 'utils/constants/walletTypes';
import { CUSTOM_CHANGE } from 'utils/constants/supportMessageTopics';
import { removeUrlParams } from 'utils/location';
import DropdownInput from 'ui-kit/DropdownInput';
import PromocodeRow from 'components/Exchanger/PromocodeRow';
import ExchangeRate from 'components/Exchanger/ExchangeRate';
import AdditionalChargeRow from 'components/Exchanger/AdditionalChargeRow';
import LimitRate from 'components/Exchanger/LimitRate/index';
import LimitRateError from 'components/Exchanger/LimitRateError';
import SubmitButton from 'components/Exchanger/SubmitButton';
import CardSelect from 'components/Exchanger/CardSelect';
import Input from 'components/Exchanger/Input';
import {
  pushAnalyticsEvent,
  pushAnalyticsWithParams,
} from 'components/App/analytics';
import blueTheme from 'components/App/themes/blue';
import * as animationData from 'assets/images/banner.json';

import {
  Container,
  ExchangerWrapperAdditional,
  HeaderCalculationWrapper,
  VisaIcon,
  MainContainer,
  SellContainer,
  SwapIconWrapper,
  SwapIcon,
  BuyContainer,
  SystemInfoError,
  ErrorMessage,
  ExchangerWrapper,
  LabelText,
  LabelWrapper,
  RelativeWrapper,
  CardSelectWrapper,
  CryptoNetwork,
  RadioWrapper,
  LabelRadio,
  VipRate,
  BitcashLink,
  CustomInputWrapper,
  Textarea,
} from './styled-ui';

export const getCurrencyLabel = currency => (
  <LabelWrapper>
    {currenciesLogo[currency]}
    <LabelText>
      {getCurrencyValue(currency)}
      {NETWORK_TYPES[currency] && (
        <CryptoNetwork>{NETWORK_TYPES[currency]}</CryptoNetwork>
      )}
    </LabelText>
  </LabelWrapper>
);

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const ExchangerCalculator = ({
  values,
  setCurrencyPair,
  isSubmitting,
  setValues,
  errors,
  touched,
  handleBlur,
  allPaymentsInformation,
  isCalculationLoaded,
  setIsCalculationLoaded,
  ...props
}) => {
  const { t } = useTranslation();
  const axiosInformation = useContext(InformationContext);
  const user = useContext(UserContext);
  const client = useContext(ClientContext);
  const currencies = useContext(CurrenciesContext);
  const [paymentId, setPaymentId] = useState('');
  const [isCryptoCardLimit, setIsCryptoCardLimit] = useState(false);
  const [outputAsset, setOutputAsset] = useState('');
  const information = axiosInformation.data;
  const { error } = axiosInformation;
  const queryParams = parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const promoInfo = propOr(null, 'promoCode', information);
  const promoError = pathOr(null, ['promoCode', 'status'], information);
  const isPromoRequestFinished = pathOr(false, ['promoCode'], information);
  const promoCode = JSON.parse(localStorage.getItem('promocode'));
  const isShowLimitRate =
    typeof pathOr('', ['limit', 'min'], information) === 'number' &&
    typeof pathOr('', ['limit', 'max'], information) === 'number';
  const errorStatus = propOr('', 'status', error);
  const outputValue =
    information !== null && information.calculation
      ? localeNumberFromOutputNumber(
          information.calculation.outputAsset,
          information.currencyPair.toCurrency
        )
      : '';
  const isAuthorized = user.fetching ? false : !!user.data;
  const isUserVerified = pathOr('', ['data', 'status'], client) === 'VERIFIED';
  const userStatus =
    pathOr('', ['data', 'userStatus'], user) === 'NEW'
      ? 'NEW'
      : pathOr('', ['data', 'status'], client);
  const isTestingRequired = getClientTestingNeeded(client);
  const isTokenAlreadyChosen = allPaymentsInformation?.data?.find(
    ({ id }) => id === values.paymentToken
  );
  const isPaymentNotAvailable =
    isTokenAlreadyChosen?.status === PAYMENT_STATUS.CURRENCY_DISABLED ||
    isTokenAlreadyChosen?.status === PAYMENT_STATUS.DIRECTION_DISABLED ||
    (information?.currencyPair?.fromCurrency !== FIAT_CURRENCIES.BYN &&
      values.paymentToken === ORDER_TYPES.ERIP);

  const allCurrencies = currencies?.data?.cryptoCurrencies?.concat(
    currencies?.data?.fiatCurrencies
  );

  const isInternalWallet =
    client?.data?.internalBalanceEnabled &&
    (localStorage.getItem('wallet_type') || values.walletType) ===
      walletTypes.INT;
  const isNotVerified =
    !isNil(client.data) && client?.data?.status !== 'VERIFIED';
  const isOperationDisabled =
    !isNil(information) &&
    !information?.operationStatus?.enabled &&
    information?.operationStatus?.status !== 'OPERATION_ENABLED' &&
    isUserVerified;
  const isDisplayWalletAddressInput =
    !isCrypto(values.currencyPair.fromCurrency) &&
    isAuthorized &&
    isUserVerified;
  const isDisplayWalletType =
    (!isCrypto(values.currencyPair.fromCurrency) ||
      isCrypto(values.currencyPair.fromCurrency)) &&
    isAuthorized &&
    client?.data?.internalBalanceEnabled &&
    isUserVerified;
  const isDisplayCommentInput =
    isAuthorized &&
    [CRYPTO_CURRENCIES.TON, CRYPTO_CURRENCIES.USDT_TON].includes(
      values?.currencyPair?.toCurrency
    );
  const { setFieldValue } = props;

  useEffect(() => {
    if (outputValue) {
      setOutputAsset(outputValue);
    }
  }, [outputValue]);

  const setCardInformation = ({ id, providerType }) => {
    setValues({
      ...values,
      providerType,
      paymentToken: id,
    });
    setOutputAsset('');
  };

  useEffect(() => {
    if (allPaymentsInformation.data) {
      const filteredPayments = isCrypto(values.currencyPair.fromCurrency)
        ? allPaymentsInformation.data
        : allPaymentsInformation.data.filter(
            payment => payment.providerType !== SETTLEMENT
          );
      const firstPayment = filteredPayments[0];
      const lastPaymentId =
        paymentId || localStorage.getItem('last_payment_id');
      const getPayment = filteredPayments.find(
        payment => payment.id === lastPaymentId
      );
      const setPayment =
        lastPaymentId && !isNil(getPayment) ? getPayment : firstPayment;

      if (isEmpty(filteredPayments)) {
        setCardInformation({
          id: '',
          providerType: values.action === 'sell' ? ALFA : '',
        });
      } else {
        setCardInformation(setPayment);
      }
    }
  }, [
    values.currencyPair.fromCurrency,
    values.action,
    values.currencyPair.toCurrency,
    allPaymentsInformation?.data,
  ]);

  useEffect(() => {
    if (!isNil(allPaymentsInformation.data)) {
      if (!isCrypto(values.currencyPair.toCurrency)) {
        allPaymentsInformation?.refetch({
          currencies: [values.currencyPair.toCurrency],
          fromCurrency: values.currencyPair.fromCurrency,
          toCurrency: values.currencyPair.toCurrency,
        });
      } else {
        allPaymentsInformation?.refetch({
          currencies: [values.currencyPair.fromCurrency],
          fromCurrency: values.currencyPair.fromCurrency,
          toCurrency: values.currencyPair.toCurrency,
        });
      }
    }
  }, [
    values.action,
    values.currencyPair.toCurrency,
    values.currencyPair.fromCurrency,
  ]);

  useEffect(() => {
    const body = {
      cardId: values.paymentToken,
      currency: isCrypto(values.currencyPair.fromCurrency)
        ? values.currencyPair.toCurrency
        : values.currencyPair.fromCurrency,
    };
    if (isTokenAlreadyChosen?.crypto && isCalculationLoaded && outputAsset) {
      axios.post(cryptoCardCheckLimit, body).then(({ data }) => {
        const limitSize = +data.limitSize;
        const currentValue = +data.currentValue;
        const inputValue = isCrypto(values.currencyPair.fromCurrency)
          ? fromLocaleNumber(outputAsset)
          : fromLocaleNumber(values.calculation.inputAsset);
        const isLimit = inputValue + currentValue > limitSize;
        setIsCryptoCardLimit(isLimit);
      });
    } else {
      setIsCryptoCardLimit(false);
    }
  }, [
    isTokenAlreadyChosen,
    isCalculationLoaded,
    outputAsset,
    values.paymentToken,
    values.currencyPair.fromCurrency,
    values.currencyPair.toCurrency,
    values.calculation.inputAsset,
  ]);

  useEffect(() => {
    const expiration = moment()
      .add(1, 'month')
      .format();

    if (
      queryParams?.promocode &&
      PROMOCODE_REG_EXP.test(queryParams?.promocode)
    ) {
      const object = { value: queryParams?.promocode, expiration };
      localStorage.setItem('promocode', JSON.stringify(object));
    }

    if (user?.data && localStorage.getItem('promocode')) {
      setValues({
        ...values,
        promoCode: promoCode?.value,
      });
      removeUrlParams();
    }
  }, [queryParams?.promocode, promoCode?.value, user?.data]);

  useEffect(() => {
    if (!promoError && !isNil(promoInfo)) {
      pushAnalyticsWithParams('promocode_success', {
        promocode: values.promoCode,
      });
    } else if (promoError) {
      pushAnalyticsEvent('promocode_reject');
    }
  }, [promoError, promoInfo]);

  const onClickSwapCurrencies = () => {
    const toCurrency = pathOr('', ['currencyPair', 'toCurrency'], values);
    if (isCalculationLoaded) {
      if (outputValue) {
        localStorage.setItem('input_asset', outputValue);
      } else {
        localStorage.setItem('input_asset', JSON.stringify(0));
      }
      setCurrencyPair('fromCurrency', toCurrency);
      setIsCalculationLoaded(false);
    }
    setPaymentId('');
  };

  const handleCheckBoxChange = e => {
    setValues(
      {
        ...values,
        address: null,
        walletType: e.target.value,
        comment: null,
      },
      { shouldValidate: false }
    );
    localStorage.setItem('wallet_type', e.target.value);
  };

  const handleClickBanner = () => {
    pushAnalyticsEvent('bitcash_banner_click');
  };
  const changeCommentHandler = e => {
    if (e.target?.value?.length < 100) {
      setFieldValue('comment', e.target.value);
    }
  };

  const getCardAddressPlaceComponent = block => {
    if (
      (!isCrypto(values.currencyPair.fromCurrency) && block === 'sell') ||
      (isCrypto(values.currencyPair.fromCurrency) && block === 'buy')
    ) {
      const selectValue = () => {
        if (values.action === 'sell') {
          return values.paymentToken || ORDER_TYPES.CARD;
        }
        return values.paymentToken;
      };

      return (
        isAuthorized && (
          <div>
            <CardSelectWrapper>
              <CardSelect
                setCardInformation={setCardInformation}
                selectName="card"
                isUserVerified={isUserVerified}
                userStatus={userStatus}
                selectValue={selectValue()}
                allPaymentsInformation={allPaymentsInformation}
                isTestingRequired={isTestingRequired}
                fromCurrency={values.currencyPair.fromCurrency}
                toCurrency={values.currencyPair.toCurrency}
                setPaymentId={setPaymentId}
              />
            </CardSelectWrapper>
          </div>
        )
      );
    }
    return (
      <div>
        {isDisplayWalletAddressInput && (
          <RelativeWrapper>
            <Input
              placeholder={
                isInternalWallet
                  ? t('calculationView.walletPlaceholder')
                  : t('calculationView.addressPlaceholder')
              }
              value={values.address || ''}
              name="address"
              isError={touched.address && errors.address}
              disabled={isInternalWallet}
              isWalletTypes={
                values.walletType && client?.data?.internalBalanceEnabled
              }
              isExtType={
                (localStorage.getItem('wallet_type') || values.walletType) ===
                walletTypes.EXT
              }
              {...props}
            />
            {(localStorage.getItem('wallet_type') || values.walletType) ===
              walletTypes.EXT &&
              touched.address &&
              errors.address && <ErrorMessage>{errors.address}</ErrorMessage>}
          </RelativeWrapper>
        )}

        {isDisplayCommentInput && (
          <CustomInputWrapper>
            <Textarea
              type="text"
              placeholder={t('calculationView.tonComment')}
              placeholderColor={blueTheme.grayCharge}
              value={values.comment || ''}
              name="comment"
              onChange={changeCommentHandler}
              spellCheck="false"
            />
          </CustomInputWrapper>
        )}
        {isDisplayWalletType && (
          <RadioWrapper>
            <LabelRadio>
              <input
                name="walletType"
                value={walletTypes.EXT}
                onChange={handleCheckBoxChange}
                type="radio"
                checked={
                  (localStorage.getItem('wallet_type') || values.walletType) ===
                  walletTypes.EXT
                }
              />
              <span>{t('converterCard.externalWallet')}</span>
            </LabelRadio>
            <LabelRadio>
              <input
                name="walletType"
                value={walletTypes.INT}
                onChange={handleCheckBoxChange}
                type="radio"
                checked={
                  (localStorage.getItem('wallet_type') || values.walletType) ===
                  walletTypes.INT
                }
              />
              <span>{t('converterCard.internalWallet')}</span>
            </LabelRadio>
          </RadioWrapper>
        )}
      </div>
    );
  };

  const serviceFeeValue = getServiceFeeValueCalculation(information);
  const blockchainFeeValue = getBlockchainFeeValueCalculation(information);

  const isBlockchainFeeError =
    !errors?.calculation?.inputAsset &&
    values.calculation.inputAsset === information?.calculation?.inputAsset &&
    values.calculation.inputAsset <= blockchainFeeValue;

  const isLimitExceededError = [
    t('calculationView.errors.validationErrorOutUpOfLimit'),
    t('calculationView.errors.validationErrorLimitExceeded'),
  ].includes(errors?.calculation?.inputAsset);

  return (
    <Container>
      <ExchangerWrapper isAuthorized={isAuthorized}>
        <VipRate
          to={{
            pathname: SUPPORT,
            search: `?topic=${CUSTOM_CHANGE}&message=vipRate`,
          }}
        >
          {t('calculationView.vipRate')}
        </VipRate>
        {isAuthorized && information && (
          <PromocodeRow
            isPromoRequestFinished={isPromoRequestFinished}
            promoInfo={promoInfo}
            promoError={promoError}
            t={t}
            setFieldValue={setFieldValue}
          />
        )}
        <>
          <ExchangerWrapperAdditional>
            <HeaderCalculationWrapper>
              <ExchangeRate />
              <VisaIcon />
            </HeaderCalculationWrapper>
            <Form>
              <MainContainer>
                <SellContainer>
                  <DropdownInput
                    {...props}
                    value={values.calculation.inputAsset}
                    label={t('calculationView.sellTitle')}
                    name="calculation.inputAsset"
                    currencyType={values.currencyPair.fromCurrency}
                    selectName="currencyPair.fromCurrency"
                    isShowLimitRate={isShowLimitRate}
                    setCurrencyPair={setCurrencyPair}
                    selectOptions={allCurrencies?.map(it => ({
                      value: it.currency,
                      label: getCurrencyLabel(it.currency),
                    }))}
                    ErrorComponent={() => (
                      <LimitRateError
                        information={information}
                        errorLimit={errors.calculation}
                        promoError={promoError}
                        isBlockchainFeeError={isBlockchainFeeError}
                        isLimitExceededError={isLimitExceededError}
                        isCryptoCardLimit={isCryptoCardLimit}
                      />
                    )}
                  />
                  {getCardAddressPlaceComponent('sell')}
                </SellContainer>
                <SwapIconWrapper>
                  <SwapIcon
                    onClick={onClickSwapCurrencies}
                    disabled={!isCalculationLoaded}
                  />
                </SwapIconWrapper>
                <BuyContainer>
                  <DropdownInput
                    {...props}
                    value={outputValue}
                    label={t('calculationView.buyTitle')}
                    currencyType={values.currencyPair.toCurrency}
                    selectName="currencyPair.toCurrency"
                    disabled
                    setCurrencyPair={setCurrencyPair}
                    selectOptions={allCurrencies?.map(it => ({
                      value: it.currency,
                      label: getCurrencyLabel(it.currency),
                    }))}
                  />
                  {getCardAddressPlaceComponent('buy')}
                </BuyContainer>
              </MainContainer>
              <AdditionalChargeRow
                t={t}
                chargeData={{
                  ...(serviceFeeValue && {
                    serviceFee: {
                      value: serviceFeeValue,
                      currency: getFiatCurrencyFromPair(values.currencyPair),
                    },
                  }),
                  ...(blockchainFeeValue && {
                    blockchainFee: {
                      value: blockchainFeeValue,
                      currency: getFiatCurrencyFromPair(values.currencyPair),
                    },
                  }),
                  bonus: !promoError && promoInfo,
                }}
              />
              <SubmitButton
                information={axiosInformation}
                calcError={errors.calculation || errors}
                commission={blockchainFeeValue}
                isSubmitting={isSubmitting}
                isTestingRequired={isTestingRequired}
                isPaymentNotAvailable={isPaymentNotAvailable}
                isLimitExceededError={isLimitExceededError}
                isCryptoCardLimit={isCryptoCardLimit}
                buttonName={
                  isLimitExceededError && !isOperationDisabled
                    ? t('calculationView.increaseLimitFull')
                    : t('converterCard.exchange')
                }
              />
            </Form>
          </ExchangerWrapperAdditional>
          {isNotVerified && (
            <SystemInfoError>
              <div>
                {t('calculationView.errors.validationUserVerification', {
                  operation: 'обмена',
                })}
              </div>
            </SystemInfoError>
          )}
          {errors?.orderCreation?.message && (
            <SystemInfoError>
              <div>{errors.orderCreation.message}</div>
            </SystemInfoError>
          )}
          {isAuthorized && errorStatus && (
            <SystemInfoError>
              <div>{requestErrors[error.status]}</div>
            </SystemInfoError>
          )}
          {isOperationDisabled && (
            <SystemInfoError>
              <div>
                {t(`workTimeErrors.${information?.operationStatus?.status}`, {
                  operation: 'обмена',
                })}
              </div>
            </SystemInfoError>
          )}
          {isAuthorized && isShowLimitRate && (
            <LimitRate
              t={t}
              information={information}
              errorLimit={errors.calculation}
            />
          )}
        </>
      </ExchangerWrapper>
      <BitcashLink
        onClick={handleClickBanner}
        href="https://info.whitebird.io/carta-crypto"
        target="_blank"
        rel="nofollow"
      >
        <Lottie options={defaultOptions} />
      </BitcashLink>
    </Container>
  );
};

ExchangerCalculator.defaultProps = {
  readOnly: false,
  capsLock: false,
  autoFocus: false,
};

export default ExchangerCalculator;
