import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonDocument,
  ButtonsGroup,
  DocumentItem,
  DocumentItemTitle,
  DocumentsWrapper,
  PDFIcon,
} from './styled-ui';

const InvestorsPage = () => {
  const { t } = useTranslation();

  const items = [
    {
      name: 'Баланс 1 квартал 2024',
      link: '/assets/files/balance_2024Q1.pdf',
    },
    {
      name: 'Баланс 2 квартал 2024',
      link: '/assets/files/balance_2024Q2.pdf',
    },
    {
      name: 'Баланс 4 квартал 2023',
      link: '/assets/files/balance_2023Q4.pdf',
    },
    {
      name: 'ОПИУ 31.12.2023',
      link: '/assets/files/opiu_31.12.2023.pdf',
    },
    {
      name: 'Отчёт о прибылях и убытках 1 квартал 2024',
      link: '/assets/files/report_2024Q1.pdf',
    },
    {
      name: 'Пояснительная записка 2023',
      link: '/assets/files/explanatory_note_2023.pdf',
    },
    {
      name: 'Презентация WhiteBird',
      link: '/assets/files/presentation_WB.pdf',
    },
    {
      name: 'Свидетельство ГРЮЛ WhiteBird',
      link: '/assets/files/svidetelstvo_whitebird.pdf',
    },
  ];

  return (
    <DocumentsWrapper>
      {items?.map(it => (
        <DocumentItem key={it.name}>
          <DocumentItemTitle>
            <PDFIcon />
            <div>{it.name}</div>
          </DocumentItemTitle>
          <ButtonsGroup>
            <ButtonDocument>
              <a href={it.link} target="_blank" rel="noreferrer">
                {t('investors.buttons.read')}
              </a>
            </ButtonDocument>
            <ButtonDocument blue>
              <a href={it.link} download>
                {t('investors.buttons.download')}
              </a>
            </ButtonDocument>
          </ButtonsGroup>
        </DocumentItem>
      ))}
    </DocumentsWrapper>
  );
};

export default InvestorsPage;
