import React, { useContext, useState, useReducer, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { isNil, pathOr } from 'ramda';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import wsConnectInstance from 'ws-connect';
import requestErrors from 'utils/constants/requestErrors';
import { ERROR_MESSAGES, ERROR_STATUSES } from 'utils/constants/errors';
import {
  useAxiosStateWithRefetchWithData,
  useAxiosSubmitingEffect,
} from 'utils/hooks/axiosHook';
import { getUserAllPaymentOptions } from 'utils/services/request/card';
import { fromLocaleNumber } from 'utils/number';
import UserContext from 'utils/contexts/User';
import ClientContext from 'utils/contexts/Client';
import CurrenciesContext from 'utils/contexts/Currencies';
import GeoContext from 'utils/contexts/Geo';
import { getLocaleCurrency, localeCurrencies } from 'utils/location';
import {
  CRYPTO_CURRENCIES,
  cryptoCurrencies,
  INITIAL_VALUE_CURRENCIES,
} from 'utils/constant';
import { ORDER_TYPES } from 'utils/constants/paymentMethods';
import { walletTypes } from 'utils/constants/walletTypes';
import { isCrypto } from 'utils/crypto';
import { Dialog, DialogModal } from 'ui-kit/Modal/Modal';
import ContextWrap from 'components/Exchanger/ContextWrap';
import ExchangerCalculator from 'components/Exchanger/ExchangerCalculator';
import { PAYMENT_METHODS } from 'utils/constants/profileTabs';
import validationFieldsValue from './validation';

// eslint-disable-next-line consistent-return
function reducer(state, action) {
  switch (action.type) {
    case 'set': {
      return action.payload;
    }
    case 'unMount': {
      if (wsConnectInstance?.wsConnection?.connected && state?.unsubscribe) {
        state.unsubscribe();
      }
      break;
    }
    default:
  }
}

const getDefaultConverterValue = fromCurrency =>
  INITIAL_VALUE_CURRENCIES[fromCurrency] ?? INITIAL_VALUE_CURRENCIES.DEFAULT;

const getOppositeChosenCurrencyValue = (
  newValue,
  oppositeCurrency,
  currency
) => {
  // eslint-disable-next-line no-nested-ternary
  return cryptoCurrencies.includes(newValue)
    ? isCrypto(oppositeCurrency)
      ? currency
      : oppositeCurrency
    : isCrypto(oppositeCurrency)
    ? oppositeCurrency
    : currency;
};

const setCurrencyPair = (setValues, setTouched, values) => (name, newValue) => {
  const fromCurrency = name.includes('fromCurrency')
    ? newValue
    : getOppositeChosenCurrencyValue(
        newValue,
        values.currencyPair.toCurrency,
        values.currencyPair.fromCurrency
      );
  const toCurrency = name.includes('toCurrency')
    ? newValue
    : getOppositeChosenCurrencyValue(
        newValue,
        values.currencyPair.fromCurrency,
        values.currencyPair.toCurrency
      );

  // TODO: Removing currency substitution it for now due to the long BTC loading times in prod
  // localStorage.setItem('from_currency', fromCurrency);
  // localStorage.setItem('to_currency', toCurrency);

  const action = isCrypto(fromCurrency) ? 'buy' : 'sell';

  setValues({
    ...values,
    action,
    currencyPair: {
      ...(fromCurrency && { fromCurrency }),
      ...(toCurrency && { toCurrency }),
    },
    calculation: {
      inputAsset:
        localStorage.getItem('input_asset') || values.calculation.inputAsset,
    },
  });
};

const Exchanger = ({ refresh, isOrderUpdated }) => {
  const [wsSubscription, dispatch] = useReducer(reducer, null);
  const [additionalSub, setAdditionalSub] = useState(false);
  const [isCalculationLoaded, setIsCalculationLoaded] = useState(false);
  const [isPaymentError, setPaymentError] = useState(false);
  const [isDisplayCalculator, setIsDisplayCalculator] = useState(false);
  const user = useContext(UserContext);
  const client = useContext(ClientContext);
  const currencies = useContext(CurrenciesContext);
  const geo = useContext(GeoContext);
  const history = useHistory();

  const handleSocketComing = message => {
    if (message.body) {
      const messageBody = JSON.parse(message.body);
      if (messageBody.type === 'ORDER_STATUS_CHANGED') {
        // TODO handle socket refresh only on non-processing requests: stateSubmittingForm.fetching!==true
        // TODO now have issue with getting actual fetching state on new message
        // refresh();
      }
    }
  };

  const isSocketConnected = wsConnectInstance?.wsConnection?.connected;

  useEffect(() => {
    if (isSocketConnected && !wsSubscription) {
      const subscription = wsConnectInstance?.wsConnection?.subscribe(
        '/user/notifications/orders/events',
        handleSocketComing
      );
      dispatch({ type: 'set', payload: subscription });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSocketConnected, additionalSub]);

  useEffect(() => {
    setTimeout(() => setAdditionalSub(true), 1000);
    return () => dispatch({ type: 'unMount' });
  }, []);

  const getSourceValue = (currency, walletType) =>
    isCrypto(currency) &&
    client?.data?.internalBalanceEnabled &&
    (localStorage.getItem('wallet_type') || walletType) === walletTypes.INT
      ? walletTypes.INT
      : walletTypes.EXT;

  const stateSubmitingForm = useAxiosSubmitingEffect({
    method: 'post',
    url: `${process.env.REACT_APP_EXCHANGE_URL}/orders`,
  });
  const onSubmitHandler = (values, actions) => {
    const {
      paymentToken,
      calculation: calc,
      walletType,
      comment,
      ...data
    } = values;
    const fromSourceValue = getSourceValue(
      values.currencyPair.fromCurrency,
      walletType
    );
    const toSourceValue = getSourceValue(
      values.currencyPair.toCurrency,
      walletType
    );

    const isTONCurrency = [
      CRYPTO_CURRENCIES.TON,
      CRYPTO_CURRENCIES.USDT_TON,
    ].includes(data.currencyPair?.toCurrency);

    const requestData = {
      ...data,
      ...(paymentToken !== ORDER_TYPES.SETTLEMENT &&
        paymentToken !== ORDER_TYPES.ERIP && {
          paymentInfo: {
            paymentToken,
          },
        }),
      ...(isTONCurrency &&
        comment && {
          comment,
        }),
      calculation: {
        inputAsset: fromLocaleNumber(calc.inputAsset),
      },
      fromSource: fromSourceValue,
      toSource: toSourceValue,
    };
    stateSubmitingForm.resetError();
    stateSubmitingForm.setFormAndSubmit({ values: requestData, actions });
  };

  const { t } = useTranslation();

  const errorModal = () => (
    <DialogModal
      centered
      Component={Dialog}
      mainBtnColor
      title={t('calculationView.errorPaymentModal.title')}
      acceptButtonName={t('calculationView.errorPaymentModal.addCardAccept')}
      acceptFunc={() => history.push(`/profile?tab=${PAYMENT_METHODS}`)}
      onClose={() => setPaymentError(false)}
      t={t}
    />
  );

  useEffect(() => {
    if (stateSubmitingForm.fetching) {
      stateSubmitingForm.form.actions.setSubmitting(true);
    }
    if (stateSubmitingForm.loaded) {
      if (isOrderUpdated) {
        stateSubmitingForm.form.actions.setSubmitting(false);
      }
      const { actions } = stateSubmitingForm.form;
      if (stateSubmitingForm.error) {
        actions.setSubmitting(false);
        actions.setStatus(undefined);
        if (stateSubmitingForm.error.status === ERROR_STATUSES.NOT_FOUND) {
          refresh();
        }
        if (
          stateSubmitingForm.error.status === ERROR_STATUSES.INVALID_ADDRESS
        ) {
          actions.setErrors({
            address: t('calculationView.errors.validationErrorWalletFormat'),
          });
        }
        if (stateSubmitingForm.error.status === 'INVALID_PROVIDER_TYPE') {
          setPaymentError(true);
          actions.setErrors({
            orderCreation: {
              message: t(
                'calculationView.errors.responseErrors.NO_PAYMENT_TOKEN',
                { operation: 'обмена' }
              ),
            },
          });
        }
        if (
          Object.keys(requestErrors).includes(stateSubmitingForm.error.status)
        ) {
          const isNoPaymentToken =
            stateSubmitingForm.error?.message ===
            ERROR_MESSAGES.NO_PAYMENT_TOKEN;
          let message = '';
          if (isNoPaymentToken) {
            setPaymentError(true);
            message = 'calculationView.errors.responseErrors.NO_PAYMENT_TOKEN';
          } else {
            message = `calculationView.errors.responseErrors.${stateSubmitingForm.error?.status}`;
          }
          actions.setErrors({
            orderCreation: {
              message: t(message, { operation: 'обмена' }),
            },
          });
        }
        if (stateSubmitingForm.error.message === ERROR_MESSAGES.ORDER_FAILED) {
          actions.setErrors({
            orderCreation: {
              message: t(`calculationView.errors.validationErrorProvider`),
            },
          });
        }
      } else {
        refresh();
      }
    }
    if (!stateSubmitingForm.error && stateSubmitingForm.form) {
      const { actions } = stateSubmitingForm.form;
      actions.setErrors({});
    }
  }, [
    refresh,
    stateSubmitingForm,
    stateSubmitingForm.loaded,
    stateSubmitingForm.error,
    isOrderUpdated,
    t,
  ]);

  const defaultCurrency =
    localeCurrencies[localStorage.getItem('locale')] ||
    (geo.data?.country && getLocaleCurrency(geo.data.country)) ||
    'USD';

  const initialValues = {
    promoCode: '',
    action: isCrypto(localStorage.getItem('from_currency')) ? 'buy' : 'sell',
    currencyPair: {
      fromCurrency: localStorage.getItem('from_currency') || defaultCurrency,
      toCurrency: localStorage.getItem('to_currency') || 'ETH',
    },
    calculation: {
      inputAsset:
        localStorage.getItem('input_asset') ||
        getDefaultConverterValue(
          localStorage.getItem('from_currency') || defaultCurrency
        ),
    },
    address: null,
    orderType: 'DEFAULT',
    providerType: '',
    paymentToken: '',
    walletType: walletTypes.EXT,
    fromSource: walletTypes.EXT,
    toSource: walletTypes.EXT,
    comment: null,
  };

  const allPaymentsInformation = useAxiosStateWithRefetchWithData(
    user.data
      ? getUserAllPaymentOptions(
          initialValues.currencyPair.fromCurrency,
          initialValues.currencyPair.toCurrency,
          !isCrypto(initialValues.currencyPair.toCurrency) &&
            initialValues.currencyPair.toCurrency
        )
      : {}
  );

  useEffect(() => {
    // TODO: Removing currency substitution it for now due to the long BTC loading times in prod
    localStorage.removeItem('to_currency');
    localStorage.removeItem('from_currency');
    if (defaultCurrency && !isNil(currencies?.data)) {
      setIsDisplayCalculator(true);
    }
  }, [currencies?.data, defaultCurrency]);

  const clientStatus = pathOr('', ['data', 'status'], client);

  return (
    <>
      {isDisplayCalculator && (
        <Formik
          initialValues={initialValues}
          validateOnBlur
          validate={validationFieldsValue(clientStatus, t)}
          onSubmit={onSubmitHandler}
        >
          {({
            values,
            touched,
            setValues,
            setTouched,
            validateForm,
            errors,
            ...props
          }) => (
            <ContextWrap
              values={values}
              validateForm={validateForm}
              isDisplayCalculator={isDisplayCalculator}
              setIsCalculationLoaded={setIsCalculationLoaded}
            >
              <ExchangerCalculator
                allPaymentsInformation={allPaymentsInformation}
                values={values}
                setValues={setValues}
                touched={touched}
                errors={errors}
                isCalculationLoaded={isCalculationLoaded}
                setIsCalculationLoaded={setIsCalculationLoaded}
                {...props}
                setCurrencyPair={setCurrencyPair(
                  setValues,
                  setTouched,
                  values,
                  touched
                )}
              />
            </ContextWrap>
          )}
        </Formik>
      )}
      {isPaymentError && errorModal()}
    </>
  );
};

Exchanger.defaultProps = {
  readOnly: false,
  capsLock: false,
  autoFocus: false,
};

export default Exchanger;
